// src/pages/ResourceAdmin.js
import React, { useState, useEffect } from "react";
import { getResources, createResource, updateResource, deleteResource } from "../api";

const ResourceAdmin = () => {
    const [resources, setResources] = useState([]);
    const [form, setForm] = useState({ id: null, name: "", partialAllowed: false });

    useEffect(() => {
        fetchResources();
    }, []);

    async function fetchResources() {
        const data = await getResources();
        setResources(data);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!form.name) return;
        if (form.id) {
            await updateResource(form.id, { name: form.name, partialAllowed: form.partialAllowed });
        } else {
            await createResource({ name: form.name, partialAllowed: form.partialAllowed });
        }
        setForm({ id: null, name: "", partialAllowed: false });
        fetchResources();
    };

    const handleEdit = (resItem) => {
        setForm({ id: resItem.id, name: resItem.name, partialAllowed: resItem.partialAllowed });
    };

    const handleDelete = async (id) => {
        await deleteResource(id);
        fetchResources();
    };

    const handleCancel = () => {
        setForm({ id: null, name: "", partialAllowed: false });
    };

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <h2 className="text-center mb-4">Resource Management</h2>
                    <div className="card mb-4">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label className="form-label">Resource Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={form.name}
                                        onChange={(e) => setForm({ ...form, name: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3 form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={form.partialAllowed}
                                        onChange={(e) =>
                                            setForm({ ...form, partialAllowed: e.target.checked })
                                        }
                                    />
                                    <label className="form-check-label">Allow Partial Usage</label>
                                </div>
                                <button type="submit" className="btn btn-primary w-100">
                                    {form.id ? "Update Resource" : "Create Resource"}
                                </button>
                                {form.id && (
                                    <button
                                        type="button"
                                        className="btn btn-secondary w-100 mt-2"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                )}
                            </form>
                        </div>
                    </div>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Partial Allowed</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resources.map((r) => (
                                <tr key={r.id}>
                                    <td>{r.name}</td>
                                    <td>{r.partialAllowed ? "Yes" : "No"}</td>
                                    <td>
                                        <button
                                            className="btn btn-sm btn-warning me-2"
                                            onClick={() => handleEdit(r)}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="btn btn-sm btn-danger"
                                            onClick={() => handleDelete(r.id)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ResourceAdmin;
