// src/pages/VolunteerRoleAdmin.js
import React, { useState, useEffect } from "react";
import { getVolunteerRoles, createVolunteerRole, updateVolunteerRole, deleteVolunteerRole } from "../api";

const VolunteerRoleAdmin = () => {
    const [volunteerRoles, setVolunteerRoles] = useState([]);
    const [form, setForm] = useState({ id: null, name: "", description: "" });

    useEffect(() => {
        fetchVolunteerRoles();
    }, []);

    async function fetchVolunteerRoles() {
        const data = await getVolunteerRoles();
        setVolunteerRoles(data);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!form.name) return;
        const payload = {
            name: form.name,
            description: form.description,
        };
        if (form.id) {
            await updateVolunteerRole(form.id, payload);
        } else {
            await createVolunteerRole(payload);
        }
        setForm({ id: null, name: "", description: "" });
        fetchVolunteerRoles();
    };

    const handleEdit = (role) => {
        setForm({
            id: role.id,
            name: role.name,
            description: role.description || "",
        });
    };

    const handleDelete = async (id) => {
        await deleteVolunteerRole(id);
        fetchVolunteerRoles();
    };

    const handleCancel = () => {
        setForm({ id: null, name: "", description: "" });
    };

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <h2 className="text-center mb-4">Volunteer Role Management</h2>
                    <div className="card mb-4">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label className="form-label">Role Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={form.name}
                                        onChange={(e) => setForm({ ...form, name: e.target.value })}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Description</label>
                                    <textarea
                                        className="form-control"
                                        rows="3"
                                        value={form.description}
                                        onChange={(e) => setForm({ ...form, description: e.target.value })}
                                    ></textarea>
                                </div>
                                <button type="submit" className="btn btn-primary w-100">
                                    {form.id ? "Update Role" : "Create Role"}
                                </button>
                                {form.id && (
                                    <button type="button" className="btn btn-secondary w-100 mt-2" onClick={handleCancel}>
                                        Cancel
                                    </button>
                                )}
                            </form>
                        </div>
                    </div>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Role Name</th>
                                <th>Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {volunteerRoles.map((role) => (
                                <tr key={role.id}>
                                    <td>{role.name}</td>
                                    <td>{role.description}</td>
                                    <td>
                                        <button className="btn btn-sm btn-warning me-2" onClick={() => handleEdit(role)}>
                                            Edit
                                        </button>
                                        <button className="btn btn-sm btn-danger" onClick={() => handleDelete(role.id)}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default VolunteerRoleAdmin;
