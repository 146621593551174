import React, { useState, useEffect } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import {
    getResources,
    getResourceBookings,
    createResourceBooking,
    deleteResourceBooking,
    approveResourceBooking,
    getTeams,
} from "../api";

const ResourceBookings = ({ roles }) => {
    const [resources, setResources] = useState([]);
    const [teams, setTeams] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [pagination, setPagination] = useState({}); // store page per resource
    const [form, setForm] = useState({
        id: null,
        resourceId: "",
        team: "",
        date: "",
        startTime: "",
        endTime: "",
        usageType: "full",
        usageLabel: "",
        repeatWeekly: false,
    });

    useEffect(() => {
        fetchResources();
        fetchBookings();
        fetchTeams();
    }, []);

    async function fetchResources() {
        const data = await getResources();
        setResources(data);
    }

    async function fetchBookings() {
        const data = await getResourceBookings();
        setBookings(data);
    }

    async function fetchTeams() {
        const data = await getTeams();
        setTeams(data);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!form.resourceId || !form.date || !form.startTime || !form.endTime) return;
        try {
            await createResourceBooking(form);
            setForm({
                id: null,
                resourceId: "",
                team: "",
                date: "",
                startTime: "",
                endTime: "",
                usageType: "full",
                usageLabel: "",
                repeatWeekly: false,
            });
            fetchBookings();
        } catch (err) {
            console.error("Error creating booking:", err);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteResourceBooking(id);
            fetchBookings();
        } catch (err) {
            console.error("Error deleting booking:", err);
        }
    };

    const handleApprove = async (id, newStatus) => {
        try {
            await approveResourceBooking(id, newStatus);
            fetchBookings();
        } catch (err) {
            console.error("Error approving booking:", err);
        }
    };

    const times = [];
    for (let hour = 8; hour < 22; hour++) {
        for (let quarter = 0; quarter < 4; quarter++) {
            const hh = String(hour).padStart(2, "0");
            const mm = String(quarter * 15).padStart(2, "0");
            times.push(`${hh}:${mm}`);
        }
    }
    times.push("22:00");

    const isAdmin = roles.includes("club_admin") || roles.includes("super_admin");
    const today = new Date();

    return (
        <div className="container-fluid mt-4">
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    <h2 className="text-center mb-4">Resource Bookings</h2>
                    <div className="card mb-4">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Resource</label>
                                        <select className="form-select" value={form.resourceId} onChange={(e) => setForm({ ...form, resourceId: e.target.value })}>
                                            <option value="">Select Resource</option>
                                            {resources.map(r => <option key={r.id} value={r.id}>{r.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Team</label>
                                        <select className="form-select" value={form.team} onChange={(e) => setForm({ ...form, team: e.target.value })}>
                                            <option value="">Select Team</option>
                                            {teams.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Date</label>
                                        <input type="date" className="form-control" value={form.date} onChange={(e) => setForm({ ...form, date: e.target.value })} />
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Start</label>
                                        <select className="form-select" value={form.startTime} onChange={(e) => setForm({ ...form, startTime: e.target.value })}>
                                            <option value="">Start</option>
                                            {times.map(t => <option key={t} value={t}>{t}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">End</label>
                                        <select className="form-select" value={form.endTime} onChange={(e) => setForm({ ...form, endTime: e.target.value })}>
                                            <option value="">End</option>
                                            {times.map(t => <option key={t} value={t}>{t}</option>)}
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Usage</label>
                                        <select className="form-select" value={form.usageType} onChange={(e) => setForm({ ...form, usageType: e.target.value })}>
                                            <option value="full">Full</option>
                                            <option value="partial">Partial</option>
                                        </select>
                                    </div>
                                    {form.usageType === "partial" && (
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Label</label>
                                            <input className="form-control" value={form.usageLabel} onChange={(e) => setForm({ ...form, usageLabel: e.target.value })} />
                                        </div>
                                    )}
                                </div>

                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" checked={form.repeatWeekly} onChange={(e) => setForm({ ...form, repeatWeekly: e.target.checked })} />
                                    <label className="form-check-label">Repeat Weekly (for 6 weeks)</label>
                                </div>

                                <button type="submit" className="btn btn-primary w-100">Create Booking</button>
                            </form>
                        </div>
                    </div>

                    <h4 className="mt-5">Bookings & Calendars</h4>
                    {[...resources].sort((a, b) => a.name.localeCompare(b.name)).map(resource => {
                        const resourceBookings = bookings
                            .filter(b => b.resourceId === resource.id && new Date(b.date) >= today)
                            .sort((a, b) => b.startTime > a.startTime ? -1 : 1)
                            .sort((a, b) => new Date(b.date) - new Date(a.date));

                        const page = pagination[resource.id] || 0;
                        const pageSize = 10;
                        const totalPages = Math.ceil(resourceBookings.length / pageSize);
                        const paginated = resourceBookings.slice(page * pageSize, (page + 1) * pageSize);

                        return (
                            <div key={resource.id} className="mb-5">
                                <h5 className="mb-3">{resource.name}</h5>
                                <table className="table table-sm table-bordered resource-table">
                                    <thead>
                                        <tr>
                                            <th>Team</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Day</th>
                                            <th>Usage</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginated.map(b => {
                                            const team = teams.find(t => t.id === b.team);
                                            return (
                                                <tr key={b.id}>
                                                    <td>{team?.name || "Team"}</td>
                                                    <td>{b.date}</td>
                                                    <td>{b.startTime} - {b.endTime}</td>
                                                    <td>{new Date(b.date).toLocaleDateString("en-US", { weekday: "long" })}</td>
                                                    <td>{b.usageType === "full" ? "Full" : `Partial (${b.usageLabel})`}</td>
                                                    <td>{b.status}</td>
                                                    <td>
                                                        <button className="btn btn-sm btn-danger me-2" onClick={() => handleDelete(b.id)}>Delete</button>
                                                        {isAdmin && b.status === "pending" && (
                                                            <>
                                                                <button className="btn btn-sm btn-success me-1" onClick={() => handleApprove(b.id, "approved")}>✔</button>
                                                                <button className="btn btn-sm btn-warning" onClick={() => handleApprove(b.id, "rejected")}>✖</button>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                                {totalPages > 1 && (
                                    <div className="d-flex justify-content-end mb-3">
                                        <ul className="pagination pagination-sm mb-0">
                                            {Array.from({ length: totalPages }, (_, i) => (
                                                <li key={i} className={`page-item ${i === page ? "active" : ""}`}>
                                                    <button className="page-link" onClick={() => setPagination(prev => ({ ...prev, [resource.id]: i }))}>
                                                        {i + 1}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                <FullCalendar
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    initialView="timeGridWeek"
                                    allDaySlot={false}
                                    firstDay={1}
                                    slotMinTime="08:00:00"
                                    slotMaxTime="22:00:00"
                                    slotMinHeight={60}
                                    displayEventTime={false}
                                    height="auto"
                                    events={resourceBookings.map(b => {
                                        const team = teams.find(t => t.id === b.team);
                                        return {
                                            id: b.id,
                                            title: `${team?.name || "Team"}${b.usageType === "partial" ? ` (${b.usageLabel})` : ""}`,
                                            start: `${b.date}T${b.startTime}`,
                                            end: `${b.date}T${b.endTime}`,
                                            backgroundColor: b.status === "approved" ? "#198754" : "#ffc107",
                                            borderColor: "#000"
                                        };
                                    })}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ResourceBookings;
