// src/utils/formatting.js

export function formatDateTime24(dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    });
}

export function formatScore(result) {
    if (result.ftHome != null && result.ftAway != null) {
        return `${result.ftHome} - ${result.ftAway}`;
    }
    return `${result.homeScore || '-'} : ${result.awayScore || '-'}`;
}


export function formatDate(dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
}

export function formatTime(dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" });
}

export function computeDay(dateStr) {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const date = new Date(dateStr);
    return days[date.getDay()];
}

/**
 * Returns a human-readable relative time string.
 */
export function timeAgo(timestamp) {
    if (!timestamp) return "Unknown";
    const now = new Date();
    const past = new Date(timestamp);
    const diffMs = now - past;
    const diffSec = Math.round(diffMs / 1000);
    const diffMin = Math.round(diffSec / 60);
    const diffHr = Math.round(diffMin / 60);
    const diffDay = Math.round(diffHr / 24);
    if (diffSec < 60) return `${diffSec} sec ago`;
    if (diffMin < 60) return `${diffMin} min ago`;
    if (diffHr < 24) {
        const remainderMin = diffMin % 60;
        return `${diffHr} hr${diffHr > 1 ? "s" : ""}${remainderMin > 0 ? " " + remainderMin + " min" : ""} ago`;
    }
    return `${diffDay} day${diffDay > 1 ? "s" : ""} ago`;
}

/**
 * Computes the grade based on gender and age.
 * For example, if gender is "WOMEN" and age is "Under 18", returns "Under 18 WOMEN".
 */
export function computeGrade(item) {
    // Adjust order if needed; here age comes first if provided.
    return (item.age ? item.age + " " : "") + item.gender;
}

/**
 * Returns a CSS class name for row coloring based on gender.
 */
export function rowClassName(gender) {
    switch (gender?.toUpperCase()) {
        case "WOMEN":
            return "row-women";
        case "GIRLS":
            return "row-girls";
        case "BOYS":
            return "row-boys";
        case "MEN":
            return "row-men";
        default:
            return "";
    }
}
