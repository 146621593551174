// src/components/MatchTable.js

import React from "react";

const MatchTable = ({ columns, data, cellStyle, rowClassName }) => {
    return (
        <table className="table">
            <thead>
                <tr>
                    {columns.map((col, idx) => (
                        <th key={idx} style={{ ...cellStyle, ...col.headerStyle }}>
                            {col.header}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.length === 0 ? (
                    <tr>
                        <td style={cellStyle} colSpan={columns.length}>
                            No data found
                        </td>
                    </tr>
                ) : (
                    data.map((item, idx) => (
                        <tr
                            key={item.key || `${item.dateAndTime}-${item.homeTeam}-${idx}`}
                            className={rowClassName ? rowClassName(item.gender) : ""}
                        >
                            {columns.map((col, cidx) => (
                                <td key={cidx} style={{ ...cellStyle, ...col.cellStyle }}
                                    className={rowClassName ? rowClassName(item.gender) : ""}
                                >
                                    {col.render ? col.render(item) : item[col.accessor]}
                                </td>
                            ))}
                        </tr>
                    ))
                )}
            </tbody>
        </table>
    );
};

export default MatchTable;
