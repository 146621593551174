// src/firebase.js
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore"; // Import Firestore module
import { firebaseConfig } from "./firebaseConfig";

// Verify that firebaseConfig is not empty
if (!firebaseConfig || Object.keys(firebaseConfig).length === 0) {
    throw new Error("Firebase configuration is missing or empty.");
}

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export default firebase;
