// src/AdminDashboard.js

import React, { useState, useEffect } from "react";
import {
    getUsers,
    createUser,
    updateUser,
    deleteUser,
    getClubs,
    createClub,
    updateClub,
    deleteClub,
} from "../api";
import { auth } from "../firebase";
import firebase from "../firebase";
import "bootstrap/dist/css/bootstrap.min.css";

// Secure hook to fetch the current user's roles from Firestore.
function useUserRole() {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchUserRole() {
            const user = auth.currentUser;
            if (user) {
                try {
                    const doc = await firebase.firestore().collection("users").doc(user.uid).get();
                    if (doc.exists && doc.data().roles) {
                        setRoles(doc.data().roles);
                    } else {
                        setRoles([]);
                    }
                } catch (error) {
                    console.error("Error fetching user roles:", error);
                    setRoles([]);
                }
            }
            setLoading(false);
        }
        fetchUserRole();
    }, []);
    return { roles, loading };
}

const UsersSection = ({ clubs, refreshClubs }) => {
    const initialFormState = { uid: null, email: "", password: "", club: "", roles: ["club_admin"] };
    const [users, setUsers] = useState([]);
    const [form, setForm] = useState(initialFormState);

    const fetchUsers = async () => {
        const data = await getUsers();
        setUsers(data);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    // Force club to "global" if the roles include super_admin.
    useEffect(() => {
        if (form.roles && form.roles.includes("super_admin")) {
            setForm((prev) => ({ ...prev, club: "global" }));
        }
    }, [form.roles]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!form.email || !form.club || (!form.uid && !form.password)) return;
        const payload = {
            email: form.email,
            password: form.password,
            club: form.club,
            roles: form.roles,
        };
        if (form.uid) {
            await updateUser(form.uid, payload);
        } else {
            await createUser(payload);
        }
        setForm(initialFormState);
        fetchUsers();
    };

    const handleEdit = (user) => {
        setForm({
            uid: user.uid,
            email: user.email,
            password: "",
            club: user.club,
            roles: user.roles || [],
        });
    };

    const handleDelete = async (uid) => {
        await deleteUser(uid);
        fetchUsers();
    };

    const handleClear = () => setForm(initialFormState);
    const handleCancel = () => setForm(initialFormState);

    return (
        <div className="card mb-4">
            <div className="card-header">User Management</div>
            <div className="card-body">
                {clubs.length === 0 && (
                    <div className="alert alert-warning">
                        No clubs available. Please create a club first.
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            value={form.email}
                            onChange={(e) => setForm({ ...form, email: e.target.value })}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">
                            Password {form.uid && "(leave blank to keep current)"}
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            value={form.password}
                            onChange={(e) => setForm({ ...form, password: e.target.value })}
                            required={!form.uid}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Club</label>
                        <select
                            className="form-select"
                            value={form.club}
                            onChange={(e) => setForm({ ...form, club: e.target.value })}
                            required
                            disabled={form.roles && form.roles.includes("super_admin")}
                        >
                            <option value="">Select club</option>
                            {clubs.map((c) => (
                                <option key={c.id} value={c.name}>
                                    {c.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Roles</label>
                        <select
                            multiple
                            className="form-select"
                            value={form.roles}
                            onChange={(e) => {
                                const options = e.target.options;
                                const selected = [];
                                for (let i = 0; i < options.length; i++) {
                                    if (options[i].selected) {
                                        selected.push(options[i].value);
                                    }
                                }
                                setForm({ ...form, roles: selected });
                            }}
                            required
                        >
                            <option value="club_admin">Club Admin</option>
                            <option value="club_coach">Club Coach</option>
                            <option value="club_manager">Club Manager</option>
                            <option value="club_social">Club Social</option>
                            <option value="super_admin">Super Admin</option>
                        </select>
                    </div>
                    <div className="d-flex">
                        <button type="submit" className="btn btn-primary">
                            {form.uid ? "Update User" : "Create User"}
                        </button>
                        {form.uid ? (
                            <button type="button" className="btn btn-secondary ms-2" onClick={handleCancel}>
                                Cancel
                            </button>
                        ) : (
                            <button type="button" className="btn btn-secondary ms-2" onClick={handleClear}>
                                Clear
                            </button>
                        )}
                    </div>
                </form>
                <hr />
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Club</th>
                            <th>Roles</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.length ? (
                            users.map((u) => (
                                <tr key={u.uid}>
                                    <td>{u.email}</td>
                                    <td>{u.club}</td>
                                    <td>{u.roles && Array.isArray(u.roles) ? u.roles.join(", ") : u.roles}</td>
                                    <td>
                                        <button className="btn btn-sm btn-warning me-2" onClick={() => handleEdit(u)}>
                                            Edit
                                        </button>
                                        <button className="btn btn-sm btn-danger" onClick={() => handleDelete(u.uid)}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="text-center">
                                    No users found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const ClubsSection = ({ clubs, refreshClubs }) => {
    const initialForm = { id: null, name: "", bgColor: "#aaaaaa", fgColor: "#aaaaaa" };
    const [form, setForm] = useState(initialForm);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!form.name) return;
        if (form.id) {
            await updateClub(form.id, { name: form.name, bgColor: form.bgColor, fgColor: form.fgColor });
        } else {
            await createClub({ name: form.name, bgColor: form.bgColor, fgColor: form.fgColor });
        }
        setForm(initialForm);
        refreshClubs();
    };

    const handleEdit = (clubItem) => {
        setForm({
            id: clubItem.id,
            name: clubItem.name,
            bgColor: clubItem.bgColor || "#aaaaaa",
            fgColor: clubItem.fgColor || "#aaaaaa",
        });
    };

    const handleDelete = async (id) => {
        await deleteClub(id);
        refreshClubs();
    };

    const handleClear = () => setForm(initialForm);
    const handleCancel = () => setForm(initialForm);

    return (
        <div className="card mb-4">
            <div className="card-header">Club Management</div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Club Name</label>
                        <input
                            type="text"
                            className="form-control"
                            value={form.name}
                            onChange={(e) => setForm({ ...form, name: e.target.value })}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Background Color</label>
                        <input
                            type="color"
                            className="form-control form-control-color"
                            value={form.bgColor}
                            onChange={(e) => setForm({ ...form, bgColor: e.target.value })}
                            title="Choose your background color"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Foreground (Text) Color</label>
                        <input
                            type="color"
                            className="form-control form-control-color"
                            value={form.fgColor}
                            onChange={(e) => setForm({ ...form, fgColor: e.target.value })}
                            title="Choose your text color"
                            required
                        />
                    </div>
                    <div className="d-flex">
                        <button type="submit" className="btn btn-primary">
                            {form.id ? "Update Club" : "Create Club"}
                        </button>
                        {form.id ? (
                            <button type="button" className="btn btn-secondary ms-2" onClick={handleCancel}>
                                Cancel
                            </button>
                        ) : (
                            <button type="button" className="btn btn-secondary ms-2" onClick={handleClear}>
                                Clear
                            </button>
                        )}
                    </div>
                </form>
                <hr />
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Club Name</th>
                            <th>Background</th>
                            <th>Foreground</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clubs && clubs.length > 0 ? (
                            clubs.map((c) => (
                                <tr key={c.id}>
                                    <td>{c.name}</td>
                                    <td style={{ backgroundColor: c.bgColor }}>{c.bgColor}</td>
                                    <td style={{ backgroundColor: c.fgColor }}>{c.fgColor}</td>
                                    <td>
                                        <button className="btn btn-sm btn-warning me-2" onClick={() => handleEdit(c)}>
                                            Edit
                                        </button>
                                        <button className="btn btn-sm btn-danger" onClick={() => handleDelete(c.id)}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="text-center">
                                    No clubs found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const AdminDashboard = () => {
    const { roles, loading } = useUserRole();
    const [clubs, setClubs] = useState([]);

    const fetchGlobalClubs = async () => {
        const data = await getClubs();
        setClubs(data);
    };

    useEffect(() => {
        fetchGlobalClubs();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    // Check if the current user has the super_admin role.
    if (!roles.includes("super_admin")) {
        return (
            <div className="container my-4">
                <div className="alert alert-danger">
                    Access Denied. Only super admin can access this dashboard.
                </div>
            </div>
        );
    }

    return (
        <div className="container my-4">
            <h2>Admin Dashboard</h2>
            <UsersSection clubs={clubs} refreshClubs={fetchGlobalClubs} />
            <ClubsSection clubs={clubs} refreshClubs={fetchGlobalClubs} />
        </div>
    );
};

export default AdminDashboard;
