// src/components/Header.js

import React from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { auth } from "../firebase";

const Header = ({ user, roles, clubName }) => {
    const handleLogout = () => {
        auth.signOut();
    };

    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/">{clubName || "RugbyCore"}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/">Home</Nav.Link>
                        {roles.includes("super_admin") && (
                            <Nav.Link as={Link} to="/admin">Admin Dashboard</Nav.Link>
                        )}
                        <NavDropdown title="Matches" id="matches-dropdown">
                            <NavDropdown.Item as={Link} to="/fixtures">Fixtures</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/results">Results</NavDropdown.Item>
                        </NavDropdown>
                        {(roles.includes("super_admin") || roles.includes("club_social")) && (
                            <Nav.Link as={Link} to="/pr">PR</Nav.Link>
                        )}
                        <NavDropdown title="Club" id="club-dropdown">
                            {roles.includes("club_admin") && (
                                <NavDropdown.Item as={Link} to="/volunteerRoles">
                                    Manage Volunteer Roles
                                </NavDropdown.Item>
                            )}
                            {roles.includes("club_admin") && (
                                <NavDropdown.Item as={Link} to="/teamAdmin">
                                    Manage Teams
                                </NavDropdown.Item>
                            )}
                            {roles.includes("club_admin") && (
                                <NavDropdown.Item as={Link} to="/resources/admin">Manage Resources</NavDropdown.Item>
                            )}
                            <NavDropdown.Item as={Link} to="/resources/bookings">Book Resources</NavDropdown.Item>
                        </NavDropdown>

                    </Nav>
                    <Nav>
                        <Navbar.Text className="me-3">
                            Signed in as: {user.email} ({roles.join(", ")})
                        </Navbar.Text>
                        <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
