// src/components/Home.js

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { getLatestMatches } from "../api";
import {
    formatDateTime24,
    formatDate,
    formatTime,
    computeDay,
    timeAgo,
    computeGrade,
    rowClassName
} from "../utils/formatting";
import MatchTable from "../components/MatchTable";

const cellStyle = { padding: "0.3rem 0.5rem" };

const Home = ({ clubName }) => {
    const [upcomingFixtures, setUpcomingFixtures] = useState([]);
    const [recentResults, setRecentResults] = useState([]);
    const [lastModified, setLastModified] = useState({ fixtures: null, results: null });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchLatest();
    }, []);

    async function fetchLatest() {
        try {
            const data = await getLatestMatches();
            setUpcomingFixtures(data.fixtures || []);
            setRecentResults(data.results || []);
            if (data.lastModified) {
                setLastModified({
                    fixtures: data.lastModified.fixtures,
                    results: data.lastModified.results,
                });
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching latest data:", error);
            setLoading(false);
        }
    }

    if (loading) {
        return (
            <div className="container mt-4">
                <h2>Loading dashboard...</h2>
            </div>
        );
    }

    // Define column definitions for upcoming fixtures.
    const fixtureColumns = [
        { header: "Grade", render: item => computeGrade(item) },
        { header: "Date", render: item => formatDate(item.dateAndTime) },
        { header: "Time", render: item => formatTime(item.dateAndTime) },
        { header: "Day", render: item => computeDay(item.dateAndTime) },
        { header: "Competition", render: item => item.competition || "-" },
        { header: "Home", render: item => item.homeTeam || "-" },
        { header: "Away", render: item => item.awayTeam || "-" },
        { header: "Venue", render: item => item.venue || "-" },
        { header: "Referee", render: item => item.referee || "-" }
    ];

    // Define column definitions for recent results.
    const resultColumns = [
        { header: "Grade", render: item => computeGrade(item) },
        { header: "Date", render: item => formatDate(item.dateAndTime) },
        { header: "Time", render: item => formatTime(item.dateAndTime) },
        { header: "Day", render: item => computeDay(item.dateAndTime) },
        { header: "Competition", render: item => item.competition || "-" },
        { header: "Home", render: item => item.homeTeam || "-" },
        { header: "Away", render: item => item.awayTeam || "-" },
        { header: "Score", render: item => (item.ftHome != null && item.ftAway != null) ? `${item.ftHome} - ${item.ftAway}` : `${item.homeScore || '-'} : ${item.awayScore || '-'}`, cellStyle: { whiteSpace: "nowrap", padding: "0.3rem 0.5rem" } },
        { header: "Result", render: item => item.result || "-" },
        { header: "Venue", render: item => item.venue || "-" },
        { header: "Referee", render: item => item.referee || "-" }
    ];

    return (
        <div className="container mt-4">
            <h3>Welcome to RugbyCore for {clubName}</h3>
            <hr />

            <h4>Upcoming Fixtures (Next 10 Days)</h4>
            {lastModified.fixtures && (
                <h6 style={{ fontSize: "0.9rem" }}>
                    Last updated: {formatDateTime24(lastModified.fixtures)} ({timeAgo(lastModified.fixtures)})
                </h6>
            )}
            <MatchTable
                columns={fixtureColumns}
                data={upcomingFixtures}
                cellStyle={cellStyle}
                rowClassName={rowClassName}
            />

            <hr />

            <h4>Recent Results (Last 10 Days)</h4>
            {lastModified.results && (
                <h6 style={{ fontSize: "0.9rem" }}>
                    Last updated: {formatDateTime24(lastModified.results)} ({timeAgo(lastModified.results)})
                </h6>
            )}
            <MatchTable
                columns={resultColumns}
                data={recentResults}
                cellStyle={cellStyle}
                rowClassName={rowClassName}
            />
        </div>
    );
};

export default Home;
