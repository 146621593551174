// src/pages/Fixtures.js

import React, { useEffect, useState } from "react";
import { getFixtures } from "../api";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    formatDateTime24,
    formatDate,
    formatTime,
    computeDay,
    timeAgo,
    computeGrade,
    rowClassName
} from "../utils/formatting";
import MatchTable from "../components/MatchTable";
// Optionally, if you want to use the filter component:
// import FilterSection from "../components/FilterSection";

const cellStyle = { padding: "0.3rem 0.5rem" };

const Fixtures = () => {
    const [fixtures, setFixtures] = useState([]);
    const [filteredFixtures, setFilteredFixtures] = useState([]);
    const [grades, setGrades] = useState([]);
    const [teams, setTeams] = useState([]);
    const [filters, setFilters] = useState({
        grade: "ALL",
        team: "ALL",
        startDate: "",
        endDate: ""
    });
    const [lastModified, setLastModified] = useState(null);

    useEffect(() => {
        fetchFixtures();
    }, []);

    async function fetchFixtures() {
        try {
            const response = await getFixtures();
            const data = response.data;
            setFixtures(data);
            setFilteredFixtures(data);
            setLastModified(response.lastModified);

            const gradeSet = new Set(data.map(f => computeGrade(f)).filter(Boolean));
            setGrades(Array.from(gradeSet).sort());

            const teamSet = new Set();
            data.forEach(f => {
                if (f.homeTeam) teamSet.add(f.homeTeam);
                if (f.awayTeam) teamSet.add(f.awayTeam);
            });
            setTeams(Array.from(teamSet).sort());
        } catch (error) {
            console.error("Error fetching fixtures:", error);
        }
    }

    function applyFilters() {
        const { grade, team, startDate, endDate } = filters;
        let filtered = fixtures;
        if (grade !== "ALL") {
            filtered = filtered.filter(f => computeGrade(f) === grade);
        }
        if (team !== "ALL") {
            filtered = filtered.filter(f => f.homeTeam === team || f.awayTeam === team);
        }
        if (startDate) {
            filtered = filtered.filter(f => new Date(f.dateAndTime) >= new Date(startDate));
        }
        if (endDate) {
            filtered = filtered.filter(f => new Date(f.dateAndTime) <= new Date(endDate));
        }
        setFilteredFixtures(filtered);
    }

    // Define columns for the Fixtures table.
    const columns = [
        {
            header: "Grade",
            render: item => computeGrade(item)
        },
        {
            header: "Date",
            render: item => formatDate(item.dateAndTime)
        },
        {
            header: "Time",
            render: item => formatTime(item.dateAndTime)
        },
        {
            header: "Day",
            render: item => computeDay(item.dateAndTime)
        },
        {
            header: "Competition",
            render: item => item.competition || "-"
        },
        {
            header: "Home",
            render: item => item.homeTeam || "-"
        },
        {
            header: "Away",
            render: item => item.awayTeam || "-"
        },
        {
            header: "Venue",
            render: item => item.venue || "-"
        },
        {
            header: "Referee",
            render: item => item.referee || "-"
        }
    ];

    return (
        <div className="container mt-4">
            <h2>Fixtures Detail View</h2>
            {lastModified && (
                <h6 style={{ fontSize: "0.9rem" }}>
                    Last updated: {formatDateTime24(lastModified)} ({timeAgo(lastModified)})
                </h6>
            )}

            {/* Inline filter section – you could replace this with <FilterSection /> if desired */}
            <div className="card mb-4">
                <div className="card-body">
                    <div className="row g-2">
                        <div className="col-md-3">
                            <label className="form-label">Grade:</label>
                            <select
                                value={filters.grade}
                                onChange={e =>
                                    setFilters({ ...filters, grade: e.target.value })
                                }
                                className="form-select"
                            >
                                <option value="ALL">ALL</option>
                                {grades.map(g => (
                                    <option key={g} value={g}>
                                        {g}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">Team:</label>
                            <select
                                value={filters.team}
                                onChange={e =>
                                    setFilters({ ...filters, team: e.target.value })
                                }
                                className="form-select"
                            >
                                <option value="ALL">ALL</option>
                                {teams.map(team => (
                                    <option key={team} value={team}>
                                        {team}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">Start Date:</label>
                            <input
                                type="date"
                                value={filters.startDate}
                                onChange={e =>
                                    setFilters({ ...filters, startDate: e.target.value })
                                }
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">End Date:</label>
                            <input
                                type="date"
                                value={filters.endDate}
                                onChange={e =>
                                    setFilters({ ...filters, endDate: e.target.value })
                                }
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="mt-3">
                        <button onClick={applyFilters} className="btn btn-primary">
                            Filter
                        </button>
                    </div>
                </div>
            </div>

            <MatchTable
                columns={columns}
                data={filteredFixtures}
                cellStyle={cellStyle}
                rowClassName={rowClassName}
            />
        </div>
    );
};

export default Fixtures;
