// src/api.js

import { auth } from "./firebase";

const BASE_URL =
    process.env.REACT_APP_IS_LOCAL === "true"
        ? "http://localhost:8080"
        : "https://rugbycore-backend-52547475673.us-central1.run.app";

async function getAuthToken() {
    const currentUser = auth.currentUser;
    if (currentUser) {
        return await currentUser.getIdToken(true);
    }
    return null;
}

async function apiRequest(endpoint, method = "GET", data = null) {
    const token = await getAuthToken();
    const headers = { Authorization: `Bearer ${token}` };
    if (data) {
        headers["Content-Type"] = "application/json";
    }
    const response = await fetch(`${BASE_URL}${endpoint}`, {
        method,
        headers,
        body: data ? JSON.stringify(data) : undefined,
    });
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
    }
    return response.json();
}

export function getUsers() {
    return apiRequest("/admin/users", "GET");
}

export function getClubs() {
    return apiRequest("/admin/clubs", "GET");
}

export function createUser(data) {
    return apiRequest("/admin/createUser", "POST", data);
}

export function createClub(data) {
    return apiRequest("/admin/createClub", "POST", data);
}

export function updateClub(id, data) {
    return apiRequest(`/admin/clubs/${id}`, "PUT", data);
}

export function updateUser(uid, data) {
    return apiRequest(`/admin/users/${uid}`, "PUT", data);
}

export function deleteUser(uid) {
    return apiRequest(`/admin/users/${uid}`, "DELETE");
}

export function deleteClub(id) {
    return apiRequest(`/admin/clubs/${id}`, "DELETE");
}

export function getFixtures() {
    return apiRequest("/api/matches/fixtures", "GET");
}

export function getResults() {
    return apiRequest("/api/matches/results", "GET");
}

export function getLatestMatches() {
    return apiRequest("/api/matches/latest", "GET");
}

export function getUserDetails() {
    return apiRequest("/admin/userDetails", "GET");
}


// -- Resources --
export function getResources() {
    return apiRequest("/api/resources", "GET");
}

export function createResource(data) {
    return apiRequest("/api/resources", "POST", data);
}

export function updateResource(id, data) {
    return apiRequest(`/api/resources/${id}`, "PUT", data);
}

export function deleteResource(id) {
    return apiRequest(`/api/resources/${id}`, "DELETE");
}

// -- Bookings --
export function getResourceBookings() {
    return apiRequest("/api/resources/bookings", "GET");
}

export function createResourceBooking(data) {
    return apiRequest("/api/resources/bookings", "POST", data);
}

export function deleteResourceBooking(id) {
    return apiRequest(`/api/resources/bookings/${id}`, "DELETE");
}

export function approveResourceBooking(id, status) {
    return apiRequest(`/api/resources/bookings/${id}/approve`, "POST", { status });
}


export function getTeams() {
    return apiRequest("/api/teams", "GET");
}

export function createTeam(data) {
    return apiRequest("/api/teams", "POST", data);
}

export function updateTeam(id, data) {
    return apiRequest(`/api/teams/${id}`, "PUT", data);
}

export function deleteTeam(id) {
    return apiRequest(`/api/teams/${id}`, "DELETE");
}


// -- Volunteer Roles --
export function getVolunteerRoles() {
    return apiRequest("/api/volunteerRoles", "GET");
}

export function createVolunteerRole(data) {
    return apiRequest("/api/volunteerRoles", "POST", data);
}

export function updateVolunteerRole(id, data) {
    return apiRequest(`/api/volunteerRoles/${id}`, "PUT", data);
}

export function deleteVolunteerRole(id) {
    return apiRequest(`/api/volunteerRoles/${id}`, "DELETE");
}