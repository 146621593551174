// src/pages/TeamAdmin.js
import React, { useState, useEffect } from "react";
import { getTeams, createTeam, updateTeam, deleteTeam } from "../api";
import { getVolunteerRoles } from "../api";

const TeamAdmin = () => {
    const [teams, setTeams] = useState([]);
    const [volunteerRoles, setVolunteerRoles] = useState([]);
    const [form, setForm] = useState({
        id: null,
        name: "",
        gender: "mixed", // "male", "female", or "mixed"
        // Volunteers will be stored as an array of objects: { name, roles: [] }
        volunteers: [],
        dobStart: "",
        dobEnd: ""
    });

    useEffect(() => {
        fetchTeams();
        fetchVolunteerRoles();
    }, []);

    async function fetchTeams() {
        const data = await getTeams();
        setTeams(data);
    }

    async function fetchVolunteerRoles() {
        const data = await getVolunteerRoles();
        setVolunteerRoles(data);
    }

    // Add a new volunteer row
    const addVolunteer = () => {
        setForm({
            ...form,
            volunteers: [...form.volunteers, { name: "", roles: [] }]
        });
    };

    // Remove a volunteer at a given index
    const removeVolunteer = (index) => {
        const newVolunteers = form.volunteers.filter((_, i) => i !== index);
        setForm({ ...form, volunteers: newVolunteers });
    };

    // Update a volunteer's field
    const handleVolunteerChange = (index, field, value) => {
        const newVolunteers = [...form.volunteers];
        newVolunteers[index] = { ...newVolunteers[index], [field]: value };
        setForm({ ...form, volunteers: newVolunteers });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            name: form.name,
            gender: form.gender,
            // Pass the structured array of volunteers
            volunteers: form.volunteers,
            dobStart: form.dobStart,
            dobEnd: form.dobEnd
        };
        if (form.id) {
            await updateTeam(form.id, payload);
        } else {
            await createTeam(payload);
        }
        setForm({
            id: null,
            name: "",
            gender: "mixed",
            volunteers: [],
            dobStart: "",
            dobEnd: ""
        });
        fetchTeams();
    };

    const handleEdit = (team) => {
        setForm({
            id: team.id,
            name: team.name,
            gender: team.gender,
            volunteers: team.volunteers || [],
            dobStart: team.dobStart,
            dobEnd: team.dobEnd
        });
    };

    const handleDelete = async (id) => {
        await deleteTeam(id);
        fetchTeams();
    };

    const handleCancel = () => {
        setForm({
            id: null,
            name: "",
            gender: "mixed",
            volunteers: [],
            dobStart: "",
            dobEnd: ""
        });
    };

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <h2 className="text-center mb-4">Team Management</h2>
                    <div className="card mb-4">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label className="form-label">Team Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={form.name}
                                        onChange={(e) =>
                                            setForm({ ...form, name: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Gender</label>
                                    <select
                                        className="form-select"
                                        value={form.gender}
                                        onChange={(e) =>
                                            setForm({ ...form, gender: e.target.value })
                                        }
                                    >
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="mixed">Mixed</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Date of Birth Range</label>
                                    <div className="d-flex">
                                        <input
                                            type="date"
                                            className="form-control me-2"
                                            value={form.dobStart}
                                            onChange={(e) =>
                                                setForm({ ...form, dobStart: e.target.value })
                                            }
                                        />
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={form.dobEnd}
                                            onChange={(e) =>
                                                setForm({ ...form, dobEnd: e.target.value })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Volunteers</label>
                                    {form.volunteers.map((vol, index) => (
                                        <div key={index} className="mb-3 border p-2 rounded">
                                            <div className="mb-2">
                                                <label className="form-label">Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={vol.name}
                                                    onChange={(e) =>
                                                        handleVolunteerChange(index, "name", e.target.value)
                                                    }
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label className="form-label">Roles</label>
                                                <select
                                                    multiple
                                                    className="form-select"
                                                    value={vol.roles}
                                                    onChange={(e) => {
                                                        const options = e.target.options;
                                                        const selected = [];
                                                        for (let i = 0; i < options.length; i++) {
                                                            if (options[i].selected) {
                                                                selected.push(options[i].value);
                                                            }
                                                        }
                                                        handleVolunteerChange(index, "roles", selected);
                                                    }}
                                                >
                                                    {volunteerRoles.map((role) => (
                                                        <option key={role.id} value={role.name}>
                                                            {role.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-danger"
                                                onClick={() => removeVolunteer(index)}
                                            >
                                                Remove Volunteer
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={addVolunteer}
                                    >
                                        Add Volunteer
                                    </button>
                                </div>
                                <button type="submit" className="btn btn-primary w-100">
                                    {form.id ? "Update Team" : "Create Team"}
                                </button>
                                {form.id && (
                                    <button
                                        type="button"
                                        className="btn btn-secondary w-100 mt-2"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                )}
                            </form>
                        </div>
                    </div>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Gender</th>
                                <th>Volunteers</th>
                                <th>DOB Range</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {teams.map((team) => (
                                <tr key={team.id}>
                                    <td>{team.name}</td>
                                    <td>{team.gender}</td>
                                    <td>
                                        {team.volunteers && team.volunteers.length > 0
                                            ? team.volunteers.map((vol, i) => (
                                                <div key={i}>
                                                    {vol.name} ({vol.roles.join(", ")})
                                                </div>
                                            ))
                                            : "N/A"}
                                    </td>
                                    <td>
                                        {team.dobStart} to {team.dobEnd}
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-sm btn-warning me-2"
                                            onClick={() => handleEdit(team)}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="btn btn-sm btn-danger"
                                            onClick={() => handleDelete(team.id)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TeamAdmin;
