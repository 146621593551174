// src/Login.js

import React, { useState } from "react";
import { auth } from "../firebase";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(""); // Track error messages

    const handleSignIn = async (e) => {
        e.preventDefault();
        setError(""); // Reset error state before attempting sign-in
        try {
            await auth.signInWithEmailAndPassword(email, password);
        } catch (err) {
            // Show a user-friendly error message
            setError("Invalid email or password. Please try again.");
            console.error("Error during sign-in:", err);
        }
    };

    return (
        <div className="login-container">
            <h2>Admin Login</h2>
            <form onSubmit={handleSignIn} className="login-form">
                <div className="login-field">
                    <label>Email</label>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="login-field">
                    <label>Password</label>
                    <input
                        type="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <div className="login-error">{error}</div>}
                <button type="submit">Sign In</button>
            </form>
        </div>
    );
};

export default Login;
