// src/App.js

import React, { useEffect, useState } from "react";
import { auth } from "./firebase";

import Login from "./components/Login";
import Header from "./components/Header";
import Home from "./components/Home";
import AdminDashboard from "./components/AdminDashboard";
import Fixtures from "./pages/Fixtures";
import Results from "./pages/Results";
import PR from "./pages/PR";
import ResourceAdmin from "./pages/ResourceAdmin";
import ResourceBookings from "./pages/ResourceBookings";
import VolunteerRoleAdmin from "./pages/VolunteerRoleAdmin";
import TeamAdmin from "./pages/TeamAdmin";


import "./styles/global.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { getUserDetails, getClubs } from "./api";

function App() {
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]); // store roles as an array
  const [clubName, setClubName] = useState(null);
  const [theme, setTheme] = useState({ bgColor: "#ffffff", fgColor: "#000000" });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      setUser(firebaseUser);
      if (firebaseUser) {
        try {
          const userDetails = await getUserDetails();
          // Use the roles array if available; if not, fall back to wrapping the single role in an array.
          const userRoles = userDetails.roles
            ? userDetails.roles
            : (userDetails.role ? [userDetails.role] : []);
          setRoles(userRoles.length > 0 ? userRoles : ["default"]);
          setClubName(userDetails.club || "RugbyCore");
          // Fetch club theme using getClubs API
          if (userDetails.club) {
            const clubsData = await getClubs();
            const matchingClub = clubsData.find((c) => c.name === userDetails.club);
            if (matchingClub) {
              setTheme({
                bgColor: matchingClub.bgColor || "#ffffff",
                fgColor: matchingClub.fgColor || "#000000"
              });
            }
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
          setRoles(["default"]);
        }
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      {user && <Header user={user} roles={roles} clubName={clubName} />}
      <div
        style={{
          backgroundColor: theme.bgColor,
          color: theme.fgColor,
          minHeight: "100vh",
          transition: "background-color 0.3s ease, color 0.3s ease"
        }}
      >
        <Routes>
          <Route path="/" element={user ? <Home user={user} roles={roles} clubName={clubName} /> : <Login />} />
          <Route path="/admin" element={user && roles.includes("super_admin") ? <AdminDashboard /> : <Navigate to="/" replace />} />
          <Route path="/pr" element={user && (roles.includes("super_admin") || roles.includes("club_social")) ? <PR clubName={clubName} /> : <Navigate to="/" replace />} />
          <Route path="/fixtures" element={user ? <Fixtures /> : <Login />} />
          <Route path="/results" element={user ? <Results /> : <Login />} />
          <Route
            path="/resources/admin"
            element={
              user && roles.includes("club_admin") ? <ResourceAdmin /> : <Navigate to="/" replace />
            }
          />
          <Route
            path="/resources/bookings"
            element={user ? <ResourceBookings roles={roles} /> : <Login />}
          />
          <Route path="/volunteerRoles" element={user && roles.includes("club_admin") ? <VolunteerRoleAdmin /> : <Navigate to="/" replace />} />
          <Route path="/teamAdmin" element={user && roles.includes("club_admin") ? <TeamAdmin /> : <Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
